/** @jsx jsx */
import React from "react"

import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import Footer from "gatsby-theme-blog/src/components/home-footer"
import PostTitle from "gatsby-theme-blog/src/components/post-title"
import PostList from "gatsby-theme-blog/src/components/post-list"
import { Link } from "gatsby"
import { Styled, css, jsx } from "theme-ui";

const Posts = ({ location, posts, siteTitle, socialLinks }) => (
  <Layout location={location} title={siteTitle}>
    <SEO title="Magento PWA Blog" />
    <main>
      <div className={'hero-container'}>
          <div
              css={css({
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                  mb: 0,
              })}
          >
              <PostTitle>{'Blog'}</PostTitle>
              <Styled.a
                as={Link}
                sx={{
                  textDecoration: `none`,
                }}
                to={"tags"}>
                View All Tags
              </Styled.a>
          </div>
      </div>
      <PostList posts={posts} />
    </main>
    <Footer socialLinks={socialLinks} />
  </Layout>
)

export default Posts
